import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, onSnapshot, getDoc, } from "firebase/firestore";
import { FBDataBase } from '../Firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth functions


const UserDataContext = createContext({});

export const useUserData = () => {
  return useContext(UserDataContext);
}

export const UserDataProvider = ({children}) => {

  const [loading, setLoading] = useState(true);
  const [UID, setUID] = useState();
  const [userData, setUserData] = useState();
  const [userCompanyData, setUserCompanyData] = useState([]);

  useEffect(() => {
    const auth = getAuth(); // Initialize Firebase Auth
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        const uid = user.uid;
        setUID(uid);
        // Fetch user data from Firestore
        const userDocRef = doc(FBDataBase, "users", uid);
        onSnapshot(userDocRef, (document) => {
          let userData = document.data()
          setUserData(userData);
          if (userData.companyLinks.length > 0) {
            let companiesArray = [];
            // Map companyLinks to an array of promises
            let companyPromises = userData.companyLinks.map(docTitle => {
              const companyDocRef = doc(FBDataBase, "companies", docTitle);
              return getDoc(companyDocRef);
            });
      
            // Use Promise.all to wait for all promises to resolve
            Promise.all(companyPromises)
            .then((companySnapshots) => {
              companySnapshots.forEach((coSnapshot) => {
                if (coSnapshot.exists()) {
                  companiesArray.push(coSnapshot.data());
                }
              });
      
              setUserCompanyData(companiesArray);
            })
            .catch((error) => {
              console.error("Error: ", error)
            });
          } else {
            setUserCompanyData([]);
          }
        });
      } else {
        // User is signed out
        setUID(null);
        setUserData(null);
        setUserCompanyData([]);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <UserDataContext.Provider value={{UID, userData, userCompanyData, loading, setUID, setUserData, setUserCompanyData}}>
      {children}
    </UserDataContext.Provider>
  );
}