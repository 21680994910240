import logo from '../imgs/white_indian.png'
import './Splash.css';
import React, {useEffect, useState} from "react";
import TabContact from './TabContent/TabContact'
import TabHome from './TabContent/TabHome'
import TabLogin from './TabContent/TabLogin';
import TabDocuments from './TabContent/TabDocuments';

function Splash() {

  const [chosenTab, setChosenTab] = useState("Home")


  const TabSplitter = () => {
    switch (chosenTab) {
      case "Home": return <TabHome />
      case "Documents": return <TabDocuments />
      case "Contact": return <TabContact />
      case "Login": return <TabLogin />
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div onClick={()=>setChosenTab("Home")} id='TabHome' className='nav-button'>
          <img className='logo-img' src={logo}></img>
          <h3>HOME</h3>
        </div>
        <div onClick={()=>setChosenTab("Documents")} id='TabDocuments' className='nav-button'>
          <h3>DOCUMENTS</h3>
        </div>
        <div onClick={()=>setChosenTab("Contact")} id='TabContact' className='nav-button'>
          <h3>CONTACT</h3>
        </div>
        <div onClick={()=>setChosenTab("Login")} id='TabLogin' className='nav-button'>
          <h3>LOGIN</h3>
        </div>
      </header>
      
      <div>
        <div className='white-backdrop'></div>
        <main className='mainContent'>
          {chosenTab && <TabSplitter />}
        </main>
      </div>
    </div>
  );
}

export default Splash;
