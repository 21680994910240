import emailPic from '../../imgs/email.jpg'

const TabContact = () => {
    return (
        <div className='tab-wrapper'>
            <div className='header-image-wrapper'>
                {/* <img className='header-image' src={emailPic}></img> */}
            </div>
            <div className='tab-body-wrapper'>
                <h1 className='tab-title'>How Can We Help You?</h1>

                
            </div>
        </div>
    )
}
export default TabContact