import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { UserDataProvider } from './Contexts/UserDataContext';
import Splash from './Splash/Splash';
import Dash from './Dash/Dash'


const App = () => {
  return (
    <BrowserRouter>
      <UserDataProvider>
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/dash" element={<Dash />} />
        </Routes>
      </UserDataProvider>
    </BrowserRouter>
  )
}

export default App