

const Dash = () => {

  return(
    <div></div>
  )
  
  
}

export default Dash