import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCog7ZajRLK-JfI0aKJ-C6NfxLF8AmzHzk",
  authDomain: "indian-park.firebaseapp.com",
  projectId: "indian-park",
  storageBucket: "indian-park.appspot.com",
  messagingSenderId: "606326051082",
  appId: "1:606326051082:web:16778fce481a0c49846af3",
  measurementId: "G-Z88BXLJJ0Y"
};
export const FBApp = initializeApp(firebaseConfig);
export const FBAuth = getAuth(FBApp)
export const FBStorage = getStorage(FBApp)
export const FBDataBase = getFirestore(FBApp)