import './TabStyles.css';
import park_rainbow from '../../imgs/park_rainbow.jpg'

const TabHome = () => {
    return (
        <div className='tab-wrapper'>
            <div className='header-image-wrapper bottom' style={{height: "50vh"}}>
                <img className='header-image' src={park_rainbow}></img>
            </div>   
            <div className='tab-body-wrapper'>
                <h1 className='tab-title'>Indian Park Assoc. - Founded 1937</h1>
                <h3 className='tab-header'>About</h3>
                <p className='tab-body'>- about us</p>
                <h3 className='tab-header'>Info</h3>
                <p className='tab-body'>- info</p>
            </div>
        </div>
    )
}
export default TabHome